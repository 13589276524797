body, html {
  height: 100%;
  margin: 0;
  padding: 0;
}

div.unauthorized {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
}

div.unauthorized .centered {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

div.unauthorized a {
  display: inline-flex;
  align-items: center;
  min-height: 50px;
  background-color: #484848;
  color: white;
  text-decoration: none;

  border-radius: 4px;
}

div.unauthorized .connecting-state {
  font: 20px Roboto, sans-serif;
}

a .signin-icon {
  margin: 0 10px;
}

a .signin-text {
  margin-right: 10px;
  font: 16px Roboto, sans-serif;
}

.App {
  text-align: center;
}

.sessions {
  position: absolute;
  left: 0;
  width: 300px;
  top: 0;
  bottom: 0;
  background: #eeeeee;
  border-right: 1px solid #cccccc;
  overflow: auto;
}

.events {
  position: absolute;
  left: 300px;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
}

.session {
  border: 1px solid #cccccc;
  margin: 4px;
  border-radius: 5px;
  background: #ffffff;
  cursor: pointer;
  opacity: 0.5;
}

.session.selected {
  opacity: 1;
}

.session img {
  width: 40px;
  height: 40px;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  overflow: hidden;
  float: left;
  margin: 2px 5px 0 5px;
}

.session .uuid {
  font: 11px monospace;
}

.session .useragent, .session .device {
  text-align: left;
  margin: 2px 0;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
}

.session .lastevent {
  text-align: left;
  float: left;
  font-size: 12px;
  margin-left: 2px;
  margin-bottom: 2px;
}

.session .date {
  text-align: right;
  font-size: 12px;
  margin-right: 2px;
  margin-bottom: 2px;
}

.events .event {
  text-align: left;
  border: 1px solid #eeeeee;
  margin: 10px;
  border-radius: 5px;
  padding: 3px;
}

.events .event-name {
  font-weight: 600;
}

.events .date {
  float: right;
  font-size: 12px;
  margin-right: 2px;
}

.properties {
  font-size: 12px;
  margin-left: 30px;
}

.properties .property-name {
  font-weight: 500;
}

.App .status {
  margin: 5px;
  text-align: left;
}

img.gh-avatar {
  height: 20px;
  width: 20px;
  display: inline-block;
  overflow: hidden;
  border-radius: 50%;
  line-height: 1;
  vertical-align: middle;
}

.gh-login {
  font-weight: 600;
  font-size: 14px;
  vertical-align: middle;
}

.gh-exit {
  cursor: pointer;
}

.gh-exit svg {
  vertical-align: middle;
  fill: #808080;
}

.gh-exit:hover svg {
  fill: #000000;
}

.App .connecting-state {
  float: right;
  font-size: 12px;
}
